
export const glossary = [
  {
    title: "Безответный удар",
    text: "Разновидность простого удара. Безответный удар означает: «Направленный удар. Сражается только существо, объявившее безответный удар, результат сражения определяется как по закрытому существу».",
  },
  {
    title: "Бестелесный",
    text: "Особенность карты, которая записывается в виде «Бестелесный», либо «Бестелесный: [текст]», где [текст] — особенность, связанная с бестелесностью. Бестелесные существа могут двигаться (но не перемещаться иным способом) на клетки, занятые другими картами. Если бестелесное существо оказывается на занятой клетке, оно обязано объявить движение с этой клетки при ближайшей возможности; если все единицы движения этого существа уже потрачены, и оно не может сойти с занятой клетки, то оно изгоняется. Бестелесное существо нельзя отравить, излечить, использовать против него вампиризм, добивание и трупоедство; оно не может стать хозяином небестелесному симбиоту. Когда бестелесное существо должно погибнуть — вместо этого оно изгоняется.",
  },
  {
    title: "Блокировать атаку",
    text: "Если атака блокируется, то эта атака не оказывает влияния на игру и ни одна из сопутствующих ей особенностей не срабатывает. Атака считается невыполненной, но оплачивается, если должна быть оплачена.",
  },
  {
    title: "Броня Х",
    text: "Карта с бронёй Х не получает первые Х ран от немагических атак в течение каждого хода (и своего, и противника).",
  },
  {
    title: "В начале боя",
    text: "Особенность, которая объявляется и выполняется до начала первого хода игроков. Каждая такая особенность используется 1 раз за бой. Если у обоих игроков есть карты с такими особенностями, то они используют их по очереди, начиная с Первого игрока.",
  },
  {
    title: "В начале хода",
    text: "Особенность, которая объявляется на каждом ходу игрока после того, как он открывает все свои карты. Сначала заявляются все особенности «в начале хода» активного игрока, затем — его противника. Выполняются особенности в обратном порядке.",
  },
  {
    title: "Вальхалла [текст]",
    text: "если карта находится на кладбище в начале вашего хода и попала туда при гибели от атаки карты противника, выполните [текст].",
  },
  {
    title: "Вампиризм",
    text: "Когда существо с вампиризмом наносит простым ударом X ран небестелесному существу, оно само излечивается на X (но не более, чем на оставшееся количество жизней у цели). Если величина излечения превышает количество ран на существе с вампиризмом, оно также получает дополнительные жизни, равные этой разнице.",
  },
  {
    title: "Величина атаки",
    text: "Число, указанное в свойствах или особенностях карты. В сражении это итоговое значение атаки с учётом всех модификаторов.",
  },
  {
    title: "Возродить",
    text: "Перенести карту со своего кладбища на поле боя. Карта возрождается в открытом виде рядом с картой, использующей возрождение, и только на свободную клетку. Летающие существа помещаются сразу в  дополнительную зону.",
  },
  {
    title: "Вызвать",
    text: "Перенести карту из своей колоды на поле боя. Карта вызывается в открытом виде рядом с картой, использующей вызов, и только на свободную клетку. Летающие существа помещаются сразу в дополнительную зону.",
  },
  {
    title: "Гибель (существа, карты)",
    text: "Перенос карты с поля боя или дополнительной зоны на кладбище. В момент попадания карты на кладбище срабатывают особенности «при гибели» (см. ниже).",
  },
  {
    title: "Добивание на Х",
    text: "Существо уничтожает стоящее рядом существо, у которого осталось Х или менее жизней, не нанося при этом ран. Добивание является воздействием.",
  },
  {
    title: "Дополнительные жизни",
    text: "Эффект, увеличивающий текущий максимум жизней карты. Получение ран не изменяет количества дополнительных жизней. Получение дополнительных жизней не изменяет количества ран.",
  },
  {
    title: "Если вы ходите первым/вторым",
    text: "Карта обладает особенностью, указанной после (I), только если вы ходите первым. Карта обладает особенностью, указанной после (II), только если вы ходите вторым.",
  },
  {
    title: "Защита",
    text: "Карта с защитой от какого-либо игрового действия (атаки, заклинания, воздействия) не получает от него никаких эффектов (раны не наносятся, сопутствующие особенности не срабатывают).",
  },
  {
    title: "Значение атаки",
    text: "см. «Величина атаки».",
  },
  {
    title: "Изгнать",
    text: "Перенести существо в зону изгнания.",
  },
  {
    title: "Излечение на Х",
    text: "Снимите Х ран с существа.",
  },
  {
    title: "Инкарнация Х",
    text: "Если в начале вашего хода на вашем кладбище есть карта с инкарнацией Х, положите на неё жетон инкарнации  (отмечается любым маркером; карта может иметь такие жетоны только на кладбище). Когда число жетонов инкарнации на карте достигнет Х, все жетоны снимаются, и карта выкладывается закрытой в ваш третий ряд (летающие помещаются сразу в дополнительную зону). Если пустых клеток в третьем ряду нет, карта остаётся на кладбище, пока клетка не освободится. Инкарнация используется 1 раз за бой. Если существо, уже использовавшее инкарнацию, погибает, оно изгоняется при гибели.",
  },
  {
    title: "Летающий, летающее существо",
    text: "располагается в дополнительной зоне. Может атаковать простым ударом любую карту на поле боя или в дополнительной зоне (считается ближним ударом). Защитником от простого удара летающего существа может быть другой летающий или существо, стоящее рядом с атакованным. Защитником от простого удара по летающему существу может быть только другой летающий.",
  },
  {
    title: "Направленный удар",
    text: "Разновидность простого удара. От направленного удара нельзя назначить защитника.",
  },
  {
    title: "Напротив",
    text: "на одну клетку вверх по вертикали от указанной карты с точки зрения игрока, контролирующего эту карту.",
  },
  {
    title: "Небестелесное существо (карта)",
    text: "Любое существо (карта), кроме существ (карт) с особенностью «Бестелесный».",
  },
  {
    title: "Неповоротливость Х",
    text: "Карта получает −Х к броску кубика при определении силы простого удара. Если X не указан, то Х = 1.",
  },
  {
    title: "Один (Х) раз за бой",
    text: "Карта может использовать эту особенность только 1 (Х) раз за бой.",
  },
  {
    title: "Один (Х) раз за ход",
    text: "Карта может использовать эту особенность только 1 (Х) раз за ход.",
  },
  {
    title: "Опыт в атаке ()",
    text: "Карта получает +1 к броску кубика в сражении, если атакует (см. «+Х к броску кубика»).",
  },
  {
    title: "Опыт в защите",
    text: "Карта получает +1 к броску кубика в сражении, если защищается (см. «+Х к броску кубика»).",
  },
  {
    title: "Опыт в стрельбе ()",
    text: "Карта получает +1 к броску кубика, когда нападает выстрелом (см. «+Х к броску кубика»).",
  },
  {
    title: "Орда",
    text: "В колоде может находиться 5 или менее копий карты с этой особенностью.",
  },
  {
    title: "Отравление на Х:",
    text: "если в начале своего хода существо с отравлением открылось или уже было открыто, то оно получает Х ран. Не срабатывает, если существо не открылось в начале хода.",
  },
  {
    title: "Перенаправление",
    text: "Эффект, изменяющий цель атаки. Может быть использовано только до бросков кубика. Назначение защитника также является перенаправлением. Перенаправить атаку можно только один раз.",
  },
  {
    title: "Перемещение",
    text: "Изменение местоположения карты в пределах поля боя. Перемещать можно только на незанятую клетку. Движение,  прыжок, телепортация — частные случаи перемещения.",
  },
  {
    title: "Перераспределить X ран",
    text: "Излечить существо на X, затем нанести картам, на которые перераспределяются раны, такое же число ран, какое было излечено.",
  },
  {
    title: "Попадание на кладбище",
    text: "Перенос карты на кладбище из любой зоны. Гибель является частным случаем попадания на кладбище.",
  },
  {
    title: "Предотвращение ран",
    text: "Эффект, уменьшающий количество ран, которые должны быть нанесены. Предотвращение меняет только количество нанесённых ран, не меняя величины атаки (заклинания, воздействия). См. также «Броня».",
  },
  {
    title: "При атаке (при ударе)",
    text: "Особенность, срабатывающая, когда карта совершает атаку (удар), даже если величина атаки (удара) равна 0.",
  },
  {
    title: "При гибели",
    text: "Особенность, срабатывающая, когда карта попадает из игровой зоны на кладбище.",
  },
  {
    title: "Приземлиться",
    text: "Потерять полёт. Если указано, что карта приземляется на определённую клетку(и), то контролирующий её игрок может выбрать для приземления только свободную из этих клеток; если все эти клетки заняты — карта не может потерять полёт.",
  },
  {
    title: "Пророчество Х; [текст]",
    text: "Покажите Х верхних карт вашей колоды и выполните [текст]. Затем уберите показанные карты под низ колоды.",
  },
  {
    title: "Прыжок, дальность X",
    text: "Существо тратит все единицы движения и перемещается на любую незанятую клетку поля боя в пределах Х клеток (по правилам расчёта дальности). Считается движением карты. Существо не может использовать прыжок, если уже двигалось в этот ход.",
  },
  {
    title: "Регенерация Х ()",
    text: "Если в начале своего хода существо с регенерацией открылось или уже было открыто, то оно излечивается на Х. Не срабатывает, если существо не открылось в начале хода.",
  },
  {
    title: "Ряд",
    text: "Пять клеток поля боя, расположенных по горизонтали одна за другой.",
  },
  {
    title: "Стоящая рядом карта",
    text: "Карта, которая находится сзади, напротив, справа, слева или по диагонали от карты.",
  },
  {
    title: "Сзади",
    text: "На одну клетку вниз по вертикали от указанной карты с точки зрения игрока, контролирующего эту карту.",
  },
  {
    title: "Сила атаки (удара)",
    text: "Любая атака (удар) подразделяется по силе на слабую, среднюю или сильную.",
  },
  {
    title: "Слева/справа",
    text: "На одну клетку влево/вправо по горизонтали от указанной карты с точки зрения игрока, контролирующего эту карту.",
  },
  {
    title: "Стойкость ()",
    text: "Карта со стойкостью не получает ран от воздействий противника и не может быть уничтожена воздействиями противника (например, добиванием); тем не менее она получает раны от своих воздействий (например, отравления)",
  },
  {
    title: "Строй [текст]",
    text: "Стоящие рядом друг с другом (но не по диагонали) ваши карты с этой особенностью получают [текст] (каждая свой). Если строй разрывается — например, карта отходит, — эти особенности теряются.",
  },
  {
    title: "Столбец",
    text: "Шесть клеток поля боя, расположенных по вертикали одна за другой.",
  },
  {
    title: "Телепортация",
    text: "Существо тратит все единицы движения и перемещается на любую незанятую клетку поля боя. Считается движением карты. Существо не может использовать телепортацию, если уже двигалось в этот ход.",
  },
  {
    title: "Теряет особенности",
    text: "Считается, что карта, потерявшая особенности, не обладает никакой особенностью из напечатанных на ней (они не применяются, не срабатывают, не могут быть заявлены). Если не указан период, то особенности теряются до конца боя.",
  },
  {
    title: "Трупоедство",
    text: "Когда существо с трупоедством уничтожает существо противника — изгнать погибшее существо, при этом полностью излечиться и потерять отравление. Трупоедство срабатывает при уничтожении стоящего рядом существа или при уничтожении существа ближней атакой.",
  },
  {
    title: "Удар через ряд ()",
    text: "Дальняя немагическая атака. Наносится через одну клетку по вертикали или горизонтали (пустую или занятую вашими картами). По диагонали или через клетку с картой противника бить нельзя.",
  },
  {
    title: "Уникальность",
    text: "Свойство карты, обозначается изображением короны на левой верхней грани кристалла стоимости. В отряде игрока не может быть двух или более одинаковых карт с уникальностью (но в колоде их может быть несколько). В отряде может быть несколько разных карт с уникальностью.",
  },
  {
    title: "Уничтожить",
    text: "Перенести карту с поля боя или дополнительной зоны на кладбище без нанесения ран. Считается гибелью карты.",
  },
  {
    title: "+Х (−Х) к атаке",
    text: "Существо получает +Х (−Х) к величине своей атаки.",
  },
  {
    title: "−X от атаки",
    text: "−X к величине атаки, направленной на эту цель.",
  },
  {
    title: "+Х (−Х) к броску кубика",
    text: "Карта с +Х (−Х) к броску кубика получает +Х (−Х) к числу, выпавшему на кубике.",
  },
];
